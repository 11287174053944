<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="over">
    <mat-nav-list>
      <a
        mat-list-item
        routerLink="{{ nav.link }}"
        routerLinkActive="active"
        *ngFor="let nav of navList"
        >{{ nav.label }}</a
      >
    </mat-nav-list>
  </mat-drawer>

  <mat-toolbar>
    <button
      mat-icon-button
      class="example-icon"
      *ngIf="isAuthorized"
      (click)="drawer.toggle()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <span class="app-name">{{ appName }} <sup class="version">{{ appVersion }}</sup></span>
    <span class="spacer"></span>
    <button mat-button (click)="onLogOut()" *ngIf="isAuthorized">Выход</button>
  </mat-toolbar>
  <router-outlet></router-outlet>
</mat-drawer-container>
