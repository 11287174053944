export const environment = {
  production: true,
  protocol: 'https',
  apiHost: 'api-cms.axwhite.com',
  msgHost: 'msg-crm.axwhite.com',
  firebase: {
    apiKey: 'AIzaSyD36FZDfK6XXRPu0Y6EVDiDD2WPv80sx90',
    authDomain: 'fox-club-220110.firebaseapp.com',
    databaseURL: 'https://fox-club-220110.firebaseio.com',
    projectId: 'fox-club-220110',
    storageBucket: 'fox-club-220110.appspot.com',
    messagingSenderId: '551179963232',
    appId: '1:551179963232:web:8fdd39eff75ccc983a6c05'
  }
};
