import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard  {
    constructor(private auth: AuthService, private router: Router) {}

    public canActivate(next, state): boolean {
        if (this.auth.authenticated) { return true; }

        console.info('access denied!');
        this.router.navigate(['/auth/login']);
        return false;
    }
}
