import { Component, OnInit } from '@angular/core';
import { AuthService } from './modules/core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public appName = 'CMS';
  public appVersion = '1.4.1';
  public navList = [
    { label: 'Домой', link: '/' },
    { label: 'Публикации', link: '/publications' },
    { label: 'Рекламные объявления', link: '/events' },
    { label: 'Филиалы', link: '/departments' },
    { label: 'Услуги', link: '/services' },
    { label: 'Пользователи', link: '/users' },
  ];
  public isAuthorized = false;
  public user = null;

  constructor(
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.authService.authState$.subscribe((next) => {
      this.isAuthorized = this.authService.authenticated;
    });
  }

  public onLogOut(): void {
    this.authService.logOut();
  }
}
